import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import coreAPI from "../../../../Services/core-api";
import Recaptcha from "react-recaptcha";
import AuthService from "../../../../Services/auth-service";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_css/recapcha.css";
import Messages from "../../../../Messages/Messages";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

const initialValues = {
  username: "",
  password: "",
};

function Login(props) {

  ////////////////////////////////////////
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  /////////////////////////////////////////////////// 
  var siteKey = Messages.siteKey  /// local key
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState('');
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      // .min(6, "Minimum 6 Characters")
      // .max(50, "Maximum 50 Characters")
      .email("Invalid email")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.USERNAME_REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 Characters")
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.PASSWORD_REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      coreAPI
        .post("/validatecaptcha", {
          clientKey: values.username,
          clientSecret: values.password,
          captchaResponse: captchaStatus
        })
        .then((response) => {
          ////////////////////////////////////
          const accessToken = response.data.token;
          const data = response.data;
          data['username'] = values.username;
          AuthService.setToken(data);
          saveBusinessConfigToLocalStorage();
          fetchSourceAccessList(values.username)
          .then(()=>{
            props.login(accessToken);
            disableLoading();
          })
          .catch(()=>{
            props.login(accessToken);
            disableLoading();
          });
        })
        .catch((error) => {
          let errorMsg = Messages.CommonErrorMsg;
          disableLoading();
          if (error.response != undefined) {
            errorMsg = error.response.headers.response_details;
          }
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: errorMsg,
            })
          );
        });
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-left mb-10 mb-lg-20">
        {/* <h3 className="font-size-h1">
        <FormattedMessage id="AUTH.LOGIN.TITLE" />
         
      </h3>*/}
        <img alt="Logo" className="max-h-55px" src={toAbsoluteUrl("/media/logos/dwarpaal_connect.png")} />
        <p className="text-muted font-weight-bold">

        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="">

          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Email</span>
          <br />
          <input
            placeholder="Email"

            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Password</span>

          <Input

            placeholder="Password"
            type={values.showPassword ? "text" : "password"}
            onChange={handlePasswordChange("password")}
            value={values.password}
            className={`form-control form-control-solid h-auto py-3 px-5 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />*/}
          </Link>
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-danger my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="Forgot Password" />?
          </Link>

        </div>
        <div className="form-group fv-plugins-icon-container max-h-50px">
          <Recaptcha
            sitekey={siteKey}
            render="explicit"
            onloadCallback={(captcha) => { }}
            verifyCallback={(status) => {
              if (status) {
                setVerified(true);
                setCaptchaStatus(status);
              }
            }}
          />
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting || !isVerified}
            className={`btn orange-bg btn-lg btn-block  font-weight-bold px-9 py-4 my-3`}
          >
            <span>LOGIN</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

        </div>
        <span className="font-weight-bold text-dark-50">
          Don't have an account?
        </span>
        <Link
          to="/auth/registration"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
          className="orange-text ml-1"
        >
          Sign Up
        </Link>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export function saveBusinessConfigToLocalStorage() {
  return new Promise((resolve, reject) => {
    coreAPI.get('/unitsbusinessconfig')
      .then((res) => {
        var data = res.data.businessConfig;
        var chars = res.data.otherChars;
        var externalUnitId = res.data.externalUnitId;
        var str = res.data.businessConfig.timeZone;
        var myarray = str.split(',');
        var zone = ""
        var timeZone = myarray[0];
        if (timeZone == "ET") {
          zone = "America/New_York"
        }
        else if (timeZone == "CT") {
          zone = "America/Chicago"
        }
        else if (timeZone == "PT") {
          zone = "America/Los_Angeles"
        }
        else if (timeZone == "MT") {
          zone = "America/Denver"
        }
        else if (timeZone == "Arizona") {
          zone = "America/Pheonix"
        }
        else if (timeZone == "IST") {
          zone = "Asia/Kolkata"
        }
        var hotelName = res.data.name;
        const sourceId = localStorage.getItem("sourceId")
        localStorage.setItem(`hotelName_${sourceId}`, hotelName);
        localStorage.setItem(`businessConfig_${sourceId}`, JSON.stringify(data));
        localStorage.setItem(`chars_${sourceId}`, JSON.stringify(chars));
        localStorage.setItem(`externalUnitId_${sourceId}`, externalUnitId);
        localStorage.setItem(`zone_${sourceId}`, zone);
        resolve();
      })
      .catch((error) => {
        console.error(error)
        reject();
      })
  })
}

export function fetchSourceAccessList(username) {
  return new Promise((resolve, reject) => {
  coreAPI.get(`/sourceaccess?username=${username}`)
    .then((res) => {
      localStorage.setItem("currentSource", res.data.currentSource.propertyName);
      const accessIds = JSON.stringify(res.data.sourceAccessIds);
      localStorage.setItem("sourceAccess", accessIds);
      resolve();
    })
    .catch((e) => {
      localStorage.setItem("currentSource", "Not able to fetch Sources");
      console.log(e);
      reject();
    })
  })
}

export default injectIntl(connect(null, auth.actions)(Login));
