import React from "react";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
 // type = "text",
  type ,
  ...props
}) {
//alert(type)
if(type == undefined)
{
  
type="text"
} else if(type == "number")
{
  
type="number"
} else if(type == "textarea")
{
  
type="textarea"
}
if(type == "number")
{
 return (
    <>
      {label && <label>Enter {label}</label>}
      <input
        type={type}
        min="1"
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          // customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
else
{
   return (
    <>
      {label && <label>Enter {label}</label>}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          // customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
 
}
