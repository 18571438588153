import axios from "axios";

export const LOGIN_URL = "https://api.dwarpaal.com/token";
export const REGISTER_URL = "https://api.dwarpaal.com/register";
export const REQUEST_PASSWORD_URL = "https://api.dwarpaal.com/otpreset";
export const RESET_PASSWORD_URL = "https://api.dwarpaal.com/resetpassword";

export const ME_URL = "api/me";

export function login(clientKey, clientSecret) { 
  return axios.post(LOGIN_URL, {clientKey, clientSecret});
}
 
export function register(  clientKey, clientSecret,sourceId) {
  return axios.post(REGISTER_URL, {clientKey, clientSecret ,sourceId});
}

export function requestPassword(email) {
  return axios.get(`${REQUEST_PASSWORD_URL}?email=${email}`);
}

export function resetPassword(email,otp,password) {
  return axios.post(RESET_PASSWORD_URL, {email,otp,password});
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
