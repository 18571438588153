import SecureService from "./secure-service";

const _setItem = function (key, value) {
  key = SecureService.encrypt(key);
  value = SecureService.encrypt(JSON.stringify(value));
  localStorage.setItem(key, value);
};

const _getItem = function (key) {
  const enc_key = SecureService.encrypt(key);
  if (localStorage.getItem(enc_key)) {
    return JSON.parse(SecureService.decrypt(localStorage.getItem(enc_key)));
  } else {
    console.debug(key + " is not available in Local Storage.");
    return null;
  }
};

const _clearItem = function (key) {
  key = SecureService.encrypt(key);
  localStorage.removeItem(key);
};

const _clearAll = function () {
  console.debug("Clearing cache to get new changes affected.");
  localStorage.clear();
  console.debug("Congrats, your local storage is cleared now.");
};

const StorageService = {
  setItem: _setItem,
  getItem: _getItem,
  clearItem: _clearItem,
  clearAll: _clearAll,
};

export default StorageService;