import axios from "axios";
import AuthService from "./auth-service";

const api_end_point = "https://api.dwarpaal.com";

const _get = function (contextPath, params = {}) {
  validateSession()
  const requestURL = api_end_point + contextPath;
  if (Object.keys(params).length > 0) {
    const reqeust = {
      params: params,
    };
    return axios.get(requestURL, reqeust);
  } else {
    return axios.get(requestURL);
  }
};



const _getBlob = function (contextPath, params = {}) {
  validateSession()
  const requestURL = api_end_point + contextPath;
  if (Object.keys(params).length > 0) {
    const reqeust = {
      params: params,
      responseType: 'blob'

    };
    return axios.get(requestURL, reqeust);
  } else {
    return axios.get(requestURL,{responseType: 'blob'});
  }
};

const _delete = function (contextPath, params = {}) {
  // validateSession()
   const requestURL = api_end_point + contextPath;
   if (Object.keys(params).length > 0) {
     const reqeust = {
       params: params,
     };
     return axios.delete(requestURL, reqeust);
   } else {
     return axios.delete(requestURL);
   }
 };

const _post = function (contextPath, requestBody, params = {}) {
  validateSession();
  const requestURL = api_end_point + contextPath;
  if (Object.keys(params).length > 0) {
    const requestParams = {
      params: params, 
    };
    return axios.post(requestURL, requestBody, requestParams);
  } else {
    return axios.post(requestURL, requestBody);
  }
};

// const _put = function (contextPath, requestBody) {
//   const requestURL = api_end_point + contextPath;
//   const response = axios.put(requestURL, requestBody);
//   return response;
// };



const _put = function (contextPath, requestBody, params = {}) {
  validateSession();
  const requestURL = api_end_point + contextPath;
  if (Object.keys(params).length > 0) {
    const requestParams = {
      params: params, 
    };
    return axios.put(requestURL, requestBody, requestParams);
  } else {
    return axios.put(requestURL, requestBody);
  }
};



const _putParam = function (contextPath, params = {}) {
  validateSession()
  const requestURL = api_end_point + contextPath;
  if (Object.keys(params).length > 0) {
    const reqeust = {
      params: params,
    };
    return axios.put(requestURL, reqeust);
  } else {
    return axios.put(requestURL);
  }
};




const coreAPI = {
  get: _get,
  getBlob: _getBlob,
  post: _post,
  put: _put,
  putParam: _putParam,
  delete: _delete,
};


function validateSession(){
  const now = Date.now();
  let expiresIn  = AuthService.getTokenExpiry();
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = now + (5 * 1000);
  }
    if(expiresIn < now){
      window.location.pathname = "/logout"
    }
}
export default coreAPI;