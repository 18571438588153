import * as CryptoJS from "crypto-js";

const KEY = CryptoJS.enc.Hex.parse("0123456789abcdeo");
const IV = CryptoJS.enc.Hex.parse("fedcba9876543211");

const _encrypt = function (data) {
  return CryptoJS.AES.encrypt(data, KEY, { iv: IV }).toString();
};

const _decrypt = function (data) {
  return CryptoJS.AES.decrypt(data.toString(), KEY, { iv: IV }).toString(
    CryptoJS.enc.Utf8
  );
};

const SecureService = {
  encrypt: _encrypt,
  decrypt: _decrypt,
};

export default SecureService;
