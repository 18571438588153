import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import Recaptcha from "react-recaptcha";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Messages from "../../../../Messages/Messages";
import coreAPI from "../../../../Services/core-api";
const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState('');
  const [Success, setSuccess] = useState("");
  const [Error, setError] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email()
      .min(6, "Minimum 6 Characters")
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "AUTH.FORGOT.VALIDATION.USERNAME_REQUIRED_FIELD",
        })
      ),
  });

  const siteKey = Messages.siteKey /// local key

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      coreAPI.post('/validatecaptcha',{captchaResponse: captchaStatus,email: values.email})
        .then((res) => {
          const message = res.headers.response_details;
          localStorage.setItem("user_email", values.email);
          setError(false);
          setIsRequested(true);
          setSubmitting(true);
          setSuccess(message);
          setTimeout(() => {
            window.location.href = "/auth/new-password";
          }, 3000);
        })
        .catch((err) => {
          const message = err.response.headers.response_details;
          setError(true)
          setIsRequested(false);
          setSubmitting(false);
          setSuccess(message);
        });
    },
  });


  const RenderMessage = () => {
    if (Success != "" && Error == false) {
      return (
        <div className="mb-15 alert alert-custom alert-light-success alert-dismissible">
          <div className="alert-text font-weight-bold">{Success}</div>
        </div>
      )
    } else if (Success != "" && Error == true) {
      return (
        <div className="mb-15 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{Success}</div>
        </div>
      )
    }
    else {
      return <span></span>
    }
  }

  return (
    <>

      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">

          <img alt="Logo" className="max-h-55px" src={toAbsoluteUrl("/media/logos/dwarpaal_connect.png")} />
          <p className="text-muted font-weight-bold">

          </p>
        </div>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Forgotten Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your email to reset your password
            </div>
        </div>

        <div>
          <RenderMessage />
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              placeholder="Email address"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container   mb-6">
            <Recaptcha
              sitekey={siteKey}
              render="explicit"
              onloadCallback={(captcha) => { }}
              verifyCallback={(status) => {
                if (status) {
                  setVerified(true);
                  setCaptchaStatus(status);
                }
              }}
            />
          </div>

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn orange-bg font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting || !isVerified || !formik.isValid}
            >
              Request OTP
              </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
                </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
