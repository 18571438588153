import StorageService from "./storage-service";

const access_token_key = "access_token";
const refresh_token_key = "refresh_token";
const expiry_time_key = "expiry_time";
const token_type_key = "token_type";
const username_key = "username";
const firstname_key = "firstName";
const lastname_key = "lastName";
const source_id="sourceId";

const _setToken = function (token_config) {
  StorageService.setItem(access_token_key, token_config.token);
  const decodedBody=JSON.parse(atob(token_config.token.split(".")[1]));
  localStorage.setItem(source_id,decodedBody.sourceId);
  StorageService.setItem(refresh_token_key, token_config.refresh_token);
  const tokenExpiry = Date.now() + (token_config.expiry_time * 1000);
  StorageService.setItem(expiry_time_key, tokenExpiry);
  StorageService.setItem(token_type_key, token_config.token_type);
  StorageService.setItem(username_key, token_config.username);
  StorageService.setItem(firstname_key, token_config.firstName);
  StorageService.setItem(lastname_key, token_config.lastName);
  localStorage.setItem(source_id,decodedBody.sourceId);
};
const _getAccessToken = function () {
  const access_token = StorageService.getItem(access_token_key);
  if (access_token) return access_token;

  return null;
};
const _getRefreshToken = function () {
  const refresh_token = StorageService.getItem(refresh_token_key);
  if (refresh_token) return refresh_token;

  return null;
};
const _getExpiryTime = function () {
  const expiry_time = StorageService.getItem(expiry_time_key);
  if (expiry_time) return expiry_time;

  return null;
};
const _getTokenType = function () {
  const token_type = StorageService.getItem(token_type_key);
  if (token_type) return token_type;

  return null;
};
const _clearToken = function () {
  StorageService.clearItem(access_token_key);
  StorageService.clearItem(refresh_token_key);
  StorageService.clearItem(expiry_time_key);
  StorageService.clearItem(token_type_key);
  StorageService.clearItem(username_key);
  StorageService.clearItem(firstname_key);
  StorageService.clearItem(lastname_key);
  localStorage.removeItem(source_id);
};

const _getUsername = ()=>{
 const username = StorageService.getItem(username_key);
  if (username) return username;

  return null;
}
const _getFirstname = ()=>{
 const firstname = StorageService.getItem(firstname_key);
  if (firstname) return firstname;

  return null;
}
const _getLastname = ()=>{
 const lastname = StorageService.getItem(lastname_key);
  if (lastname) return lastname;

  return null;
}



const AuthService = {
  setToken: _setToken,
  getAccessToken: _getAccessToken,
  getRefreshToken: _getRefreshToken,
  getTokenExpiry: _getExpiryTime,
  getTokenType: _getTokenType,
  clearToken: _clearToken,
  getUsername: _getUsername,
  getFirstname: _getFirstname,
  getLastname: _getLastname,
};

export default AuthService;
