/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { DropdownCustomToggler } from "../../../../_partials/dropdowns";
import AuthService from "../../../../../Services/auth-service";
import coreAPI from "../../../../../Services/core-api"
import { saveBusinessConfigToLocalStorage } from "../../../../../app/modules/Auth/pages/Login";
import { useHistory } from "react-router-dom";

export function UserProfileDropdown() {
  let history = useHistory();
  // const { user } = useSelector((state) => state.auth);
  let userLabel = "";
  if (AuthService.getFirstname()) {
    if (AuthService.getLastname()) {
      userLabel = `${AuthService.getFirstname()}  ${AuthService.getLastname()}`;
    } else {
      userLabel = AuthService.getFirstname();
    }
  } else {
    userLabel = AuthService.getUsername();
  }

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const sources = localStorage.getItem("sourceAccess");
  const currentSource = localStorage.getItem("currentSource");
  let sourceAccess = JSON.parse(sources);
  let isNull = false;
  if (sourceAccess == null || sourceAccess.length == 0) { isNull = true; };

  const switchSource = (switch_src_to, propertyName) => {
    const currentSource = localStorage.getItem("currentSource");
    const sources = localStorage.getItem("sourceAccess");
    const sourceId = parseInt(localStorage.getItem("sourceId"));
    let sourceAccess = JSON.parse(sources);
    const data = {
      "switch_src_to": switch_src_to
    }
    coreAPI.post('/token/switch', data)
      .then((res) => {
        const data = res.data;
        data['username'] = AuthService.getUsername();
        AuthService.setToken(res.data);
        for (let i = 0; i < sourceAccess.length; i++) {
          if (sourceAccess[i].propertyName == propertyName) {
            sourceAccess[i].propertyName = currentSource;
            sourceAccess[i].sourceId = sourceId;
            break;
          }
        }
        localStorage.setItem("currentSource", propertyName);
        localStorage.setItem("sourceAccess", JSON.stringify(sourceAccess));
        saveBusinessConfigToLocalStorage()
          .then(()=>{history.push('/dashboard/home');window.location.reload();})
          .catch((err) => {console.log(err);history.push('/dashboard/home')});
      })
  }

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon btn-hover-transparent-white d-block align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {/* {user.firstname} {user.lastname} */}
            {userLabel}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
              {/* {user.firstname[0]} */}
              {userLabel[0]}
            </span>
          </span>
          {!isNull && <p>
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
              You are viewing {currentSource}
            </span>
          </p>}
        </div>
      </Dropdown.Toggle>
      {/* <p>
      <div className="navi-separator mt-3">
        <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
          You are viewing {currentSource}
        </span>
      </div>
      </p> */}

      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {/* {user.firstname} {user.lastname} */}
                  {userLabel}
                </div>
                {/* <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                </span> */}
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/misc/bg-1.jpg"
                )})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {/* {user.firstname[0]} */}
                  {userLabel[0]}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {/* {user.firstname} {user.lastname} */}
                {userLabel}
              </div>
              {/* <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span> */}
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile
                </div>
                <div className="text-muted">
                  Account settings and more
                  {/* <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span> */}
                </div>
              </div>
            </div>
          </Link>

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a> */}

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a> */}

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a> */}

          <Link to="/BusinessSetup/Setup" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Business Setup
                </div>
                <div className="text-muted">
                  Business Setup and Energy Saving Configurations
                </div>
              </div>
            </div>
          </Link>

          {!isNull && <Link className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  Change Property
                </div>
                <div className="text-muted">
                  Switch between properties
                </div>
              </div>
              <Dropdown className="dropdown-inline" alignRight>
                <Dropdown.Toggle
                  className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  variant="transparent"
                  id="dropdown-toggle-top"
                  as={DropdownCustomToggler}
                >
                  <i className="ki ki-bold-more-hor" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                  <ul className="navi navi-hover py-5">
                    <Dropdown.Item
                      className="navi-text"
                      disabled
                    >
                      {currentSource}
                    </Dropdown.Item>
                    {sourceAccess.map((id) =>
                      <Dropdown.Item
                        className="navi-text"
                        onClick={() => switchSource(id.sourceId, id.propertyName)}
                      >
                        {id.propertyName}
                      </Dropdown.Item>
                    )}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Link>}
          <div className="navi-separator mt-3"></div>

          <div className="navi-separator mt-3"></div>
          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn BGcolor-orange font-weight-bold"
            >
              Sign Out
            </Link>
            {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown >
  );
}

function switchSource(switch_src_to, propertyName) {
  const currentSource = localStorage.getItem("currentSource");
  const sources = localStorage.getItem("sourceAccess");
  const sourceId = parseInt(localStorage.getItem("sourceId"));
  let sourceAccess = JSON.parse(sources);
  const data = {
    "switch_src_to": switch_src_to
  }
  coreAPI.post('/token/switch', data)
    .then((res) => {
      const data = res.data;
      data['username'] = AuthService.getUsername();
      AuthService.setToken(res.data);
      for (let i = 0; i < sourceAccess.length; i++) {
        if (sourceAccess[i].propertyName == propertyName) {
          sourceAccess[i].propertyName = currentSource;
          sourceAccess[i].sourceId = sourceId;
          break;
        }
      }
      localStorage.setItem("currentSource", propertyName);
      localStorage.setItem("sourceAccess", JSON.stringify(sourceAccess));
      saveBusinessConfigToLocalStorage()
        .then(() => window.location.reload())
        .catch(() => window.location.reload());
    })
}