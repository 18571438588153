import AuthService from "../Services/auth-service";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const token = AuthService.getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
        config.headers["Cache-Control"] ="no-cache"
        config.headers["Pragma"] ="no-cache"
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}
