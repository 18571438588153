import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import axios from "axios";
import coreAPI from "../../../../Services/core-api";
import Messages from "../../../../Messages/Messages";
import Recaptcha from "react-recaptcha";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const initialValues = {
  firstName: "",
  lastName: "",
  username: "",
  propertyName: "",
  password: "",
  confirmpassword: "",
  acceptTerms: false,
};

function Registration(props) {
  ////////////////////////////////////////
  const [Success, setSuccess] = useState("");
  const [errors, setError] = useState(false);
  const [captchaStatus, setCaptchaStatus] = useState('');

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  ////////////////////////////////////////
  const [values1, setValues1] = React.useState({
    confirmpassword: "",
    showconfirmpassword: false,
  });

  const handleClickShowconfirmpassword = () => {
    setValues1({ ...values1, showconfirmpassword: !values1.showconfirmpassword });
  };

  const handleMouseDownconfirmpassword = (event) => {
    event.preventDefault();
  };

  const handleconfirmpasswordChange = (prop) => (event) => {
    setValues1({ ...values1, [prop]: event.target.value });
  };
  ///////////////////////////////////////////////////
  //const siteKey = "6Lc-JI8aAAAAAJDWO0QodMCrZ8cb-E_ZAzuMJtVY";  // server key
  const siteKey = Messages.siteKey /// local key

  const [isVerified, setVerified] = useState(false);
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "First Name is required",
        })
      ),
    lastName: Yup.string()
      //   username: Yup.string()
      //  .min(6, "Minimum 6 Characters")
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "Last Name is required",
        })
      ),
    username: Yup.string().email()
      //   username: Yup.string()
      //  .min(6, "Minimum 6 Characters")
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "Email is required",
        })
      ),
    propertyName: Yup.string()
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "Property Name is required",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 Characters")
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "REGISTRATION.VALIDATION.PASSWORD_REQUIRED_FIELD",
        })
      ),
    confirmpassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "REGISTRATION.VALIDATION.CONFIRM_PASSWORD_REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "REGISTRATION.VALIDATION.CONFIRM_PASSWORD_DIDNT_MATCH",
          })
        ),
      }),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({
        id: "REGISTRATION.VALIDATION.MUST_ACCEPT_TERMS_CONDITIONS",
      })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      const sourceId = "DWARPAAL";
      coreAPI
        .post("/validatecaptcha", {
          clientKey: values.username,
          clientSecret: values.password,
          firstName: values.firstName,
          // propertyName: values.propertyName,
          lastName: values.lastName,
          sourceId: sourceId,
          captchaResponse: captchaStatus
        })
        .then((response) => {
          //alert("User Registration Successful...");
          var main_response = response.headers.response_details;
          setSubmitting(false);
          setSuccess(main_response);
          setError(false);
          setTimeout(() => {
            window.location.href = "/auth/login";
          }, 5000);
          disableLoading();
        })
        .catch((error) => {
          setSubmitting(false);
          setError(true);
          setSuccess(error.response.headers.response_details);
          disableLoading();
        });
      const info = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.username,
        propertyName: values.propertyName,
        password: values.password,
        confirmpassword: values.confirmpassword
      }
      coreAPI
        .post('/registrationemail', info)
        .then((res) => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
  });

  const RenderSuccess = () => {
    if (Success != "" && errors == false) {
      return (
        <div className="mb-15 alert alert-custom alert-light-success alert-dismissible">
          <div className="alert-text font-weight-bold">{Messages.RegistrationSuccess}</div>
        </div>
      )
    } else if (Success != "" && errors == true) {
      return (
        <div className="mb-15 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{Success}</div>
        </div>
      )
    }
    else {
      return <span></span>
    }
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">

        <img alt="Logo" className="max-h-55px" src={toAbsoluteUrl("/media/logos/dwarpaal_connect.png")} />
        <p className="text-muted font-weight-bold">

        </p>
      </div>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>
      <div className="mb-10">
        {/* <RenderError/> */}
        <RenderSuccess />
      </div>
      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">First Name</span>
          <br />
          <input
            placeholder="First Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "firstName"
            )}`}
            name="firstName"
            {...formik.getFieldProps("firstName")}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Last Name</span>
          <br />
          <input
            placeholder="Last Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "lastName"
            )}`}
            name="lastName"
            {...formik.getFieldProps("lastName")}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastName}</div>
            </div>
          ) : null}
        </div>


        {/* begin: username */}
        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Email</span>
          <br />
          <input
            placeholder="Email"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="username"
            {...formik.getFieldProps("username")}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          ) : null}
        </div>
        {/* end: username */}

        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Property Name</span>
          <br />
          <input
            placeholder="Property Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "propertyName"
            )}`}
            name="propertyName"
            {...formik.getFieldProps("propertyName")}
          />
          {formik.touched.propertyName && formik.errors.propertyName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.propertyName}</div>
            </div>
          ) : null}
        </div>

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Password</span>
          <br />

          <Input
            placeholder="Password"
            type={values.showPassword ? "text" : "password"}
            onChange={handlePasswordChange("password")}
            value={values.password}
            className={`form-control form-control-solid h-auto py-3 px-5 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <span className="gray-text">Confirm Password</span>
          <br />

          <Input

            placeholder="Confirm Password"
            type={values1.showconfirmpassword ? "text" : "password"}
            onChange={handleconfirmpasswordChange("confirmpassword")}
            value={values1.confirmpassword}
            className={`form-control form-control-solid h-auto py-3 px-5 ${getInputClasses(
              "confirmpassword"
            )}`}
            name="confirmpassword"
            {...formik.getFieldProps("confirmpassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowconfirmpassword}
                  onMouseDown={handleMouseDownconfirmpassword}
                >
                  {values1.showconfirmpassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirmpassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="chechkox" style={{ fontWeight: 'bold' }}>
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />I have read and agree to the &nbsp;
            <a target="_blank" className="orange-text mr-1" rel="noopener noreferrer" href="https://dwarpaal.com/privacy-policy/">Privacy Policy</a>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group fv-plugins-icon-container   mb-6">
          <Recaptcha
            sitekey={siteKey}
            render="explicit"
            onloadCallback={(captcha) => { }}
            verifyCallback={(status) => {
              if (status) {
                setVerified(true);
                setCaptchaStatus(status);
              }
            }}
          />
        </div>

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms || !isVerified
            }
            className="btn orange-bg font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
