import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const DevicesPage = lazy(() => import("./modules/Devices/pages/DevicesPage"));
const GuestsPage = lazy(() => import("./modules/Guests/pages/GuestsPage"));
const TemplatePage = lazy(() => import("./modules/Templates/pages/TemplatePage"));
const EditTemplate = lazy(() => import("./modules/Templates/pages/EditTemplate2"));
const CreateTemplate = lazy(() => import("./modules/Templates/pages/CreateTemplate"));
const SetupPage = lazy(() => import("./modules/BusinessSetup/pages/BusinessSetup"));
const GuestWidgets = lazy(() => import("./modules/GuestWidgets/pages/GuestWidgets"));
const UnitsPage = lazy(() => import("./modules/Units/pages/UnitsPage"));
const DashboardPage = lazy(() => import("./modules/Dashboard/pages/DashboardPage"));
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/devices" component={DevicesPage} />
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        {/* <ContentRoute path="/my-page" component={MyPage} /> */}
        <ContentRoute path="/guests" component={GuestsPage} />
        <ContentRoute path="/templates" component={TemplatePage} />
        <ContentRoute path="/edit-template" component={EditTemplate} />
        <ContentRoute path="/create-template" component={CreateTemplate} />

        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/devices" component={DevicesPage} />
        <Route path="/BusinessSetup" component={SetupPage} />
        <Route path="/GuestWidgets" component={GuestWidgets} />
        <Route path="/units" component={UnitsPage} />
        <Route path="/dashboard" component={DashboardPage} />
        
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
