import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    const currentPath = location.pathname;
    return currentPath === url || currentPath.startsWith(url + "/")
      ? "menu-item-active"
      : "";
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*Classic submenu*/}

        {/*Mega submenu*/}

        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/guests/guests-list"
          )}`}
        >
          <NavLink className="menu-link" to="/guests/guests-list">
            <span className="menu-text">Guest Access</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive("/units")}`}
        >
          <NavLink className="menu-link" to="/units">
            <span className="menu-text">Units</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>

        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/devices"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/devices">
            <span className="menu-text">Devices</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left" style={{top:"110%"}}>
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/devices/Devices-List"
                )}`}
              >
                <NavLink className="menu-link" to="/devices/Devices-List">
                  <span className="menu-text">Devices List</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/devices/Devices-Events"
                )}`}
              >
                <NavLink className="menu-link" to="/devices/Devices-Events">
                  <span className="menu-text">Device Operations</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/templates"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/templates">
            <span className="menu-text">Guest Connect</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left" style={{top:"110%"}}>
            <ul className="menu-subnav">
              <li
                className={`menu-item ${getMenuItemActive(
                  "/templates/email-templates"
                )}`}
              >
                <NavLink className="menu-link" to="/templates/email-templates">
                  <span className="menu-text">Email Wizard</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/templates/sms-templates"
                )}`}
              >
                <NavLink className="menu-link" to="/templates/sms-templates">
                  <span className="menu-text">SMS Wizard</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/templates/guestappwizard"
                )}`}
              >
                <NavLink className="menu-link" to="/templates/guestappwizard">
                  <span className="menu-text">Guest App Wizard</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/templates"
          )}`}
        >
          <NavLink className="menu-link" to="/templates">
            <span className="menu-text">Templates</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li> */}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
