import React, { useState } from "react";
import { useFormik, yupToFormErrors } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import coreAPI from "../../../../Services/core-api";

const email_value = localStorage.getItem("user_email");
const initialValues = {
  email: email_value,
  otp: "",
  newpassword: "",
  confirmpassword: ""
};

function NewPassword(props) {
  const { intl } = props;
  const [Success, setSuccess] = useState("");
  const [Error, setError] = useState(false);
  const [isDisabled, setDisable] = useState(false);


  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  ////////////////////////////////////////
  const [values1, setValues1] = React.useState({
    confirmpassword: "",
    showconfirmpassword: false,
  });

  const handleClickShowconfirmpassword = () => {
    setValues1({ ...values1, showconfirmpassword: !values1.showconfirmpassword });
  };

  const handleMouseDownconfirmpassword = (event) => {
    event.preventDefault();
  };

  const handleconfirmpasswordChange = (prop) => (event) => {
    setValues1({ ...values1, [prop]: event.target.value });
  };


  const NewPasswordSchema = Yup.object().shape({
    otp: Yup.string()
      .min(6, "Minimum 6 Characters")
      .required(
        intl.formatMessage({
          id: "OTP is required",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 Characters")
      .max(50, "Maximum 50 Characters")
      .required(
        intl.formatMessage({
          id: "Password is required",
        })
      ),
    confirmpassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "Confirm Password is required",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({
            id: "Passwords didn't match",
          })
        ),
      })
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const email=values.email;
      const otp=values.otp;
      const password=values.password;
      coreAPI.post('/resetpassword',{email,otp,password})
        .then((res) => {
          setError(false);
          const message = res.headers.response_details;
          setSubmitting(true);
          setSuccess(message);
          setDisable(true);
          localStorage.setItem("user_email", "");
          setTimeout(() => {
            window.location.href = "/auth/login";
          }, 3000);
        })
        .catch((err) => {
          setError(true);
          const message = err.response.headers.response_details;
          if (err.response.headers.response_code == "170") { setSubmitting(true); }
          else { setSubmitting(false); }
          setSuccess(message);
        });
    },
  });

  const RenderMessage = () => {
    if (Success != "" && Error == false) {
      return (
        <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
          <div className="alert-text font-weight-bold">{Success}</div>
        </div>
      )
    } else if (Success != "" && Error == true) {
      return (
        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
          <div className="alert-text font-weight-bold">{Success}</div>
        </div>
      )
    }
    else {
      return <span></span>
    }
  }

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <img alt="Logo" className="max-h-55px" src={toAbsoluteUrl("/media/logos/dwarpaal_connect.png")} />
          <p className="text-muted font-weight-bold">
          </p>
        </div>

        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Forgotten Password ?</h3>
          <div className="text-muted font-weight-bold">
            Enter your details below to reset your password
          </div>
        </div>

        <div>
          <RenderMessage />
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={email_value ? email_value : 'Your registered email will appear here'}
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="email"
              disabled={true}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Enter OTP"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "otp"
              )}`}
              name="otp"
              {...formik.getFieldProps("otp")}
              disabled={isDisabled}
            />
            {formik.touched.otp && formik.errors.otp ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.otp}</div>
              </div>
            ) : null}
          </div>

          {/* begin: Password */}
          <div className="form-group fv-plugins-icon-container">
            <Input
              placeholder="New Password"
              type={values.showPassword ? "text" : "password"}
              onChange={handlePasswordChange("password")}
              value={values.password}
              className={`form-control form-control-solid h-auto py-3 px-5 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
              disabled={isDisabled}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/* end: Password */}

          {/* begin: Confirm Password */}
          <div className="form-group fv-plugins-icon-container">
            <Input
              placeholder="Confirm New Password"
              type={values1.showconfirmpassword ? "text" : "password"}
              onChange={handleconfirmpasswordChange("confirmpassword")}
              value={values1.confirmpassword}
              className={`form-control form-control-solid h-auto py-3 px-5 ${getInputClasses(
                "confirmpassword"
              )}`}
              name="confirmpassword"
              {...formik.getFieldProps("confirmpassword")}
              disabled={isDisabled}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowconfirmpassword}
                    onMouseDown={handleMouseDownconfirmpassword}
                  >
                    {values1.showconfirmpassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.confirmpassword}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Confirm Password */}

          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn orange-bg font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting ||  !formik.isValid}
            >
              Submit
              </button>
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
                </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(NewPassword));
